<template>
  <div id="tfw-section-2" class="section-2">
    <div class="wrapper m-auto">
      <div class="wrap m-auto" style="">
        <h2 class="heading-4" id="section-2-row">
          EXPERIENCE A WORLD OF OPPORTUNITIES<br />
          THROUGH A CONNECTED PORTAL
        </h2>
      </div>
      <CRow>
        <CCol md="6" class="mb-3">
          <div class="purple-div-block">
            <img
              src="/img/tfw/Artboard-2.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <h4 class="heading-5">Hospitals and Organisations - Employers</h4>
            <p class="white-paragraph center">
              Your entire healthcare recruitment process taken care of – all
              from one provider and one technology platform – from attraction
              and engagement through to onboarding. Access a global pool of
              healthcare professionals, promote your organisation and see
              candidates intelligently matched with 100% accuracy to your job
              opportunities
            </p>
            <div class="mb-0 mt-auto text-center">
              <CLink
                to="/tfw/registration/organisation"
                class="btn btn-register w-button mb-2 mx-1 min-205"
              >
                <small class="text-none">Already a TalentFind user?</small
                ><span class="d-block">Register Here</span>
              </CLink>
              <CLink
                class="btn btn-register w-button mb-2 mx-1 min-205"
                href="https://www.talentfindsolutions.com"
                target="_blank"
              >
                <small class="text-none">Learn More about TalentFind</small
                ><span class="d-block">Click Here</span>
              </CLink>
            </div>
          </div>
        </CCol>
        <CCol md="6" class="mb-3">
          <div class="purple-div-block">
            <i class="fa-solid fa-graduation-cap purple-div-icon" style="font-size: 80px;"></i>
            <h4 class="heading-5">Training and Education</h4>
            <p class="white-paragraph center">
              Medical English, OET and IELTS preparation courses delivered by the UK’s leading English language providers, 
              Specialist Language Courses (SLC). 
              SLC offers an unparalleled range of healthcare exam-focused courses and tests. 
            </p>
            <CLink
              class="btn btn-register w-button mb-2"
              >Enter</CLink
            >
          </div>
        </CCol>
        <CCol md="6" class="mb-3">
          <div class="purple-div-block">
            <img
              src="/img/tfw/Artboard-6.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <h4 class="heading-5">Medical Universities</h4>
            <p class="white-paragraph center">
              Promote your students globally and provide them with a world of
              opportunity. Enable recruiters to access their profiles, engage
              and support them early on in their studies. Access our 4 year
              English language course and provide your students with the best
              opportunities
            </p>
            <CLink
              class="btn btn-register w-button"
              href="https://www.talentfindsolutions.com/contact-us"
              target="_blank"
              >CONTACT US</CLink
            >
          </div>
        </CCol>
        <CCol md="6" class="mb-3">
          <div class="purple-div-block">
            <img
              src="/img/tfw/Artboard-4.png"
              sizes="(max-width: 991px) 60px, 80px"
              alt=""
              class="purple-div-icon"
            />
            <h4 class="heading-5">Services</h4>
            <p class="white-paragraph center">
              Access information, services and resources provided to help you
              develop your career – all the support needed to secure your next
              role - from licensure and visa processing to English language or
              CME/CPD training. Or perhaps you’re an organisation that can
              provide training
            </p>
            <CLink class="btn btn-register w-button"
              >LEARN MORE</CLink
            >
          </div>
        </CCol>
      </CRow>
      <div class="w-layout-grid grid"></div>
    </div>
    <div v-if="isShowModal" class="modal" id="course-model">
      <div class="modal-content">
        <CourseList class="pt-5" @modalCallBack="courseModalCallback" @closeCourseList="closeCourseList" />
      </div>
    </div>
    <div>
      <CourseModal
        v-if="courseModal.isShowPopup"
        :modalTitle="courseModal.modalTitle"
        :modalColor="courseModal.modalColor"
        :isShowPopup="courseModal.isShowPopup"
        @courseModalCallBack="courseModalCallBack"
      />
      <div class="bg-course" style="font-size: 20px; font-weight: 700;">
        <Modal
        :modalTitle="confirmModal.modalTitle"
        :modalColor="confirmModal.modalColor"
        :modalContent="confirmModal.modalContent"
        :isShowPopup="confirmModal.isShowPopup"
        :buttons="confirmModal.buttons"
        :modalCallBack="modalCallBack"
        :closeOnBackdrop="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CourseList from "@/containers/TFW/LandingPage/CourseList.vue";
import CourseModal from "../../Course/CourseModal.vue";
import Modal from "@/components/reusable/Modal";
import StaticCourseList from "@/containers/TFW/LandingPage/StaticCourseList";
export default {
  name: "TFWSection2",
  components: {
    CourseList,
    CourseModal,
    Modal,
    StaticCourseList,
  },
  data() {
    return {
      isShowModal: false,
      courseModal: {
        modalColor: "primary",
        modalTitle: "Course Application",
        isShowPopup: false,
      },
      confirmModal: {
        modalColor: "primary",
        modalTitle: "",
        modalContent: "Thanks for registering! We will get back to you soon...",
        isShowPopup: false,
        buttons: ["OK"]
      },
    };
  },
  methods: {
    courseModalCallback() {
      this.isShowModal = false;
    },
    courseModalCallBack(data) {
      if(data) {
        this.confirmModal.isShowPopup = true;
      }
      this.courseModal.isShowPopup = false;
    },
    closeCourseList(payload) {
      this.courseModalCallback();
      this.courseModal.isShowPopup = true;
      if(payload){
        this.$router.push('/tfw/login')
      }
    },
    staticCloseCourseList(){
      this.staticCourseModalCallback();
    },
    modalCallBack(action) {
      let confirmDelete = "OK";
      if (action === confirmDelete) {
        this.confirmModal.isShowPopup = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: block;
  position: fixed;
  z-index: 1200;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-content img {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 700px;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
